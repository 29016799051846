import validate from '../lib';
import variousSettingsValidator from './variousSettingsValidation';
import variousEntitySettingsValidator from './variousEntitySettingsValidation';
import simulationSettingsValidator from './simulationSettingsValidation';
import summarySettingsValidator from './summarySettingsValidation';
import { baseProductValidators, typeValidators } from './lifeInsuranceSettingsValidation';
import { scpiValidators, dismemberedScpiValidators } from './scpiSettingsValidation';
import { contractValidators, basePerValidators } from './perSettingsValidation';

// validate file
const validateScpi = (product, distribution) => validate(scpiValidators(product, distribution));
const validateDismemberedScpi = (product, distribution) => validate(dismemberedScpiValidators(product, distribution));
const validateLifeInsurance = (type) => validate(typeValidators(type));
const validateBaseProducts = (product) => validate(baseProductValidators(product));
const validateSupports = (product) => validate(basePerValidators(product));
const validatePer = (contract) => validate(contractValidators(contract));

export const typeToValidation = {
    scpi: validateScpi,
    dismemberedScpi: validateDismemberedScpi,
    lifeInsurance: validateLifeInsurance,
    baseProducts: validateBaseProducts,
    per: validatePer,
    supports: validateSupports,
};

export const validateSimulationSettings = (settings) => validate(simulationSettingsValidator(settings))(settings);
export const validateVariousSettings = (settings) => validate(variousSettingsValidator)(settings);
export const validateVariousEntitySettings = (settings) => validate(variousEntitySettingsValidator)(settings);

export default (settings) => validate([
    ...simulationSettingsValidator(settings),
    ...variousSettingsValidator,
    ...summarySettingsValidator,
])(settings);
