import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, flow, map, filter, reduce } from 'lodash/fp';
import { compose, withProps, withState, lifecycle } from 'recompose';
import { reduxForm, Field, change, SubmissionError } from 'redux-form';
import { POST, GET } from 'utils/httpMethods';
import { GridLayout } from 'components/form/layout';
import { SearchableSelectField } from 'components/form/field';
import fullName from 'common/utils/fullName';
import listProvider from 'components/rip/List/listProvider';
import withSpinner from 'components/withSpinner';
import CheckboxButton from 'components/form/CheckboxButton';
import { withSession } from 'components/sessions';
import { setModal } from 'actions';
import Title from 'components/Title';
import Icon from 'common/components/Icon';

import './TransferOwnership.scss';

const TransferOwnership = ({ form, items, handleSubmit, users, dispatch, render, close }) => {
    if (render) {
        return (
            <div className="transfer-ownership-failed">
                <div className="not-updated">

                    <h3> <Icon icon="warning" className="fa-fw" /> La réaffectation n&apos;a pas pu se faire pour les RIP avec eDOC,<br /> le conseiller n&apos;a pas de compte eDOC</h3>

                </div>

                <button className="sendButton" onClick={() => close()}>
                    OK
                </button>
            </div>
        );
    }

    return (
        <div className="transfer-ownership">
            {isEmpty(items) ? (
                <div>Aucun RIPs à réaffecter.</div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h3>Réaffecter les RIPs en masse</h3>
                    <Title>Sélectionnez les RIPs à déplacer :</Title>
                    <label htmlFor="select-all-rips">
                        <input
                            id="select-all-rips"
                            type="checkbox"
                            onClick={(e) => {
                                const all = items.reduce((acc, { id }) => ({ ...acc, [id]: true }), {});
                                dispatch(change(form, 'rips', e.currentTarget.checked ? all : {}));
                            }}
                        />
                    &nbsp;Sélectionner tous les RIPs
                    </label>
                    <div className="rips-list">
                        {items &&
                        items.map(({ id, firstName, lastName }) => (
                            <Field
                                title={`${firstName} ${lastName}`}
                                component={CheckboxButton}
                                name={`rips[${id}]`}
                                key={id}
                            />
                        ))}
                    </div>
                    <GridLayout className="single full-width">
                        <SearchableSelectField
                            name="nextUserId"
                            title={<Title>Sélectionnez le nouveau propriétaire :</Title>}
                            required
                            options={users.map(({ id, name }) => ({ value: id, label: name }))}
                        />
                        <br />
                        <button type="submit" className="sendButton">
                            Transférer
                        </button>
                    </GridLayout>
                </form>
            )}
        </div>
    );
};

TransferOwnership.propTypes = {
    form: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    render: PropTypes.bool,
    close: PropTypes.func,
};

TransferOwnership.defaultProps = {};

export default compose(
    withSession,
    withState('render', 'setRender', false),
    reduxForm({
        form: 'transferOwnership',
        onSubmit: async (body) => {
            // Transform hash { 1: true, 123: true, 321: false }
            // Into Array of id when value was truthy
            const selectedRipsIds = Object.entries(body.rips).reduce((acc, [id, selected]) => {
                if (selected) {
                    return [...acc, id];
                }

                return acc;
            }, []);
            try {
                const res = await POST('/api/rips/changeOwnership', { body: { ...body, rips: selectedRipsIds } });

                return res.json();
            } catch (error) {
                const data = await error.response.json();
                throw new SubmissionError({
                    _error: data,
                });
            }
        },
        onSubmitSuccess: (result, dispatch, { setRender, session }) => {
            //* update shoyo uniquement pour instance theseis
            if (session.instanceTheseis) {
                const { statusCode, body: { notUpdated } } = result;

                if (statusCode !== 200 && notUpdated.length > 0) {
                    setRender(true);
                }
                if (200 === statusCode && 0 === notUpdated.length) {
                    document.location.reload();
                }
            } else {
                document.location.reload();
            }
        },
    }),
    connect(
        null,
        (dispatch) => ({
            close: () => {
                dispatch(setModal(null));
            },
        }),
    ),
    withProps({ forcedMode: 'user' }),
    listProvider,
    connect(({ data: { user } }) => ({ users: user })),
    withState('userRips', 'setUserRips', []),
    lifecycle({
        async componentDidMount() {
            const response = await GET(`/api/rips/foruser/${this.props.userId}`);
            const data = await response.json();
            this.props.setUserRips(data.items);
        },
    }),
    withProps(({ users, userRips, userId, forManager, session }) => ({
        users: flow(
            reduce((acc, user, id) => [
                ...acc,
                { id, ...user },
            ], []),
            filter(({ id, entityId }) => (session.permissions.isAdmin() || (id !== userId && (forManager ? (session.managedIds.includes(entityId)) : true)))),
            map(({ id, firstName, lastName }) => ({ id, name: fullName({ firstName, lastName }) })),
        )(users),
        items: userRips,
    })),
    withSpinner,
)(TransferOwnership);
