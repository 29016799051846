import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import SettingEntityDropdown from '../SettingEntityDropdown';
import FormFieldsWhitoutFooter from '../../FormFieldsWhitoutFooter';

const consentementInner = ({ initialValues, ...props }) => (
    <FormFieldsWhitoutFooter {...props} back="/admin/settings">
        <SettingEntityDropdown
            title="Consentement"
            name="consentement"
            isAdminReseau
            variables={{
                nomInstance: 'nom d\'affichage de l\'instance',
                nomSGA: 'nom d\'affichage du SGA',
                nomSGAInstance: 'nom d\'affichage du SGA et/ou instance',
                aNomSGAInstance: 'à nom d\'affichage du SGA et/ou instance',
                mailInstance: 'adresse mail de l\'instance',
                adresseInstance: 'adresse de l\'instance',
            }}
            {...props}
        />
    </FormFieldsWhitoutFooter>
);

consentementInner.propTypes = {
    initialValues: PropTypes.shape({
        consentement: PropTypes.string.isRequired,
    }).isRequired,
};

const consentement = compose(reduxForm({
    form: 'entity-settings-consentement-edit',
    enableReinitialize: true,
}))(consentementInner);

export default consentement;
