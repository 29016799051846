import classNames from 'classnames';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { downloadPdf } from 'actions';
import withInstanceSettings from 'components/sessions/withInstanceSettings';
import Checkbox from '../../form/Checkbox';
import { withSession } from '../../sessions';
import Title from '../../Title';
import affichageCheckboxCertificationRenseignements from '../../../common/utils/certificationRenseignements';

const Legal = ({ customer, double, fullName, session, download, rip, partner, instanceSettings: { label, emailSupport, adressePostale }, settingsEntity, settingsGlobal }) => {
    const { instanceTheseis } = session;
    const displayedName = get('settings.displayName', session);
    const societeIdentique = session.instanceSettings.label.toUpperCase() === displayedName.toUpperCase();

    const nomSociete = societeIdentique ? `la société ${displayedName}` : `les sociétés ${displayedName} et ${session.instanceSettings.label.toUpperCase()}`;
    const aNomSociete = societeIdentique ? `à la société ${displayedName}` : `aux sociétés ${displayedName} et ${session.instanceSettings.label.toUpperCase()}`;

    const consentementEntity = settingsEntity && settingsEntity.consentement;
    const consentementGlobal = settingsGlobal && settingsGlobal.global.legalDisclaimer.consentement;

    // construction de la variable "consentementText" selon la présence de paramètres reseaux
    // s'il y a des paramètres reseau, ils prennent le dessus sur les paramètres globaux
    let consentementText;
    if (consentementEntity === consentementGlobal || (null === consentementEntity || '' === consentementEntity)) {
        consentementText = consentementGlobal;
    } else {
        consentementText = consentementEntity;
    }

    const contentConsentement = consentementText && consentementText
        .replaceAll('{{nomSGAInstance}}', nomSociete)
        .replaceAll('{{aNomSGAInstance}}', aNomSociete)
        .replaceAll('{{nomInstance}}', label.toUpperCase())
        .replaceAll('{{mailInstance}}', emailSupport)
        .replaceAll('{{adresseInstance}}', adressePostale)
        .replaceAll('{{nomSGA}}', displayedName);

    return (
        <div className={classNames({ double })}>
            {session.instanceSettings.hasLegalDisclaimer && (
                <Fragment>
                    <Title>
                        <b>Cases à cocher par {fullName}</b>
                    </Title>
                    <Field
                        title={<ReactMarkdown>{contentConsentement}</ReactMarkdown>}
                        component={Checkbox}
                        name={`${customer}CollectInformationsAgreement`}
                        required
                    />
                </Fragment>
            )}
            {affichageCheckboxCertificationRenseignements(session.instanceSettings.hasAVSubscription, rip, partner) && (
                <Field
                    title={(
                        <p>
                            Dans le cadre d&apos;une souscription d&apos;un contrat d&apos;assurance, le client a certifié
                            que les renseignements figurant sur les pages de ce document sont sincères et exacts et
                            qu&apos;il a pris connaissance des&nbsp;
                            {session.instanceSettings.hasAVInformations ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a target="_blank" href="/legalDisclaimer/informations">
                                    informations communiquées préalablement à la présentation d&apos;un contrat d&apos;assurance
                                </a>) : ("informations communiquées préalablement à la présentation d'un contrat d'assurance")}
                            &nbsp;avec la remise&nbsp;
                            {instanceTheseis ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                    target="_blank"
                                    role="link"
                                    tabIndex={0}
                                    onKeyPress={(e) => {
                                        if ('Enter' === e.key) {
                                            download('/static/entree-en-relation-2019.pdf', {
                                                forcedFileName: 'entree-en-relation-2019.pdf',
                                            });
                                        }
                                    }}
                                    onClick={() => download('/static/entree-en-relation-2019.pdf', {
                                        forcedFileName: 'entree-en-relation-2019.pdf',
                                    })}
                                >
                                    du document d’entrée en relation, cf. article 521-2 du code des assurances
                                </a>) : ('du document d’entrée en relation, cf. article 521-2 du code des assurances')}
                            .
                        </p>
                    )}
                    component={Checkbox}
                    name={`${customer}CorrectInformationsAgreement`}
                    required
                />)}
            {instanceTheseis && (
                <Field
                    title={(
                        <p>
                            {`Dès signature de l’E-RIP, je suis informé(e) que je recevrai un lien sécurisé permettant d’accéder à un coffre-fort documentaire numérique, un espace de stockage en ligne et sécurisé, qui me permet de sauvegarder, consulter et archiver l’ensemble de mes documents liés à mon investissement patrimonial. 
                            Je suis informé que les intervenants à l’investissement et le cabinet/conseiller  qui suit mon dossier ${session.companyName ? session.companyName.toUpperCase() : session.entity.name.toUpperCase()} pourront avoir accès à ce coffre-fort documentaire sécurisé et aux documents qui y seront enregistrés et enregistrer eux-mêmes tout document relatif à mon dossier. Je pourrai aussi disposer de cet espace pour enregistrer des documents personnels et relatifs à l’investissement, notamment à la suite d’une demande de THESEIS et de mon conseiller afin de compléter mon dossier. 
                            Je suis informé(e) qu’il ne pourra être créé qu’un seul coffre-fort sécurisé par adresse électronique (sauf cas d’ouverture d’un coffre-fort lié à mon enfant mineur). Des listes de documents seront mises à disposition pour l’enregistrement des documents nécessaires à l’avancée de mon projet patrimonial. 
                            Je suis informé(e) que je pourrais m’adresser à THESEIS pour toute demande de précision quant à l’utilisation, demande de modification, de changement de mon conseiller/cabinet ou de suppression de mon coffre-fort documentaire sécurisé. Ce coffre-fort restera actif conformément au délai de conservation des données personnelles.`}

                        </p>
                    )}

                    component={Checkbox}
                    name={`${customer}CollectInformationsShoyo`}
                    required
                />
            )}
        </div>
    );
};

Legal.propTypes = {
    fullName: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    double: PropTypes.bool,
    partner: PropTypes.bool,
    instanceSettings: PropTypes.shape({
        label: PropTypes.string,
        emailSupport: PropTypes.string,
        adressePostale: PropTypes.string,
    }),
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
            hasLegalDisclaimer: PropTypes.bool,
            hasAVSubscription: PropTypes.bool,
            hasAVInformations: PropTypes.bool,
        }),
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        isUserPiloteShoyo: PropTypes.bool,
        companyName: PropTypes.string,
    }).isRequired,
    download: PropTypes.func.isRequired,
    rip: PropTypes.shape({}).isRequired,
    settingsGlobal: PropTypes.shape({
        global: PropTypes.shape({
            legalDisclaimer: PropTypes.shape({
                consentement: PropTypes.string,
            }),
        }),
    }).isRequired,
    settingsEntity: PropTypes.string.isRequired,
};

Legal.defaultProps = {
    double: false,
};

export default compose(
    withSession,
    withInstanceSettings,
    connect(null, { download: downloadPdf }),
)(Legal);
