import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import hasPartner from 'common/utils/hasPartner';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import Card from './InvestorProfileCard';
import './InvestorProfile.css';
import Error from '../../Error';

const InvestorProfile = ({
    familySituation,
    firstName,
    lastName,
    partnerFirstName,
    partnerLastName,
    investorProfiles,
    isErrors,
    submitSucceeded,
    displayEntity,
}) => {
    const withPartner = hasPartner(familySituation);
    const clientData = get('client', investorProfiles);
    const partnerData = get('partner', investorProfiles);

    return (
        <FormGroup>
            <Title>Définissez votre profil investisseur à l&apos;aide du questionnaire de risque*</Title>
            <div className="investor-profile-cards">
                <Card
                    firstName={firstName}
                    lastName={lastName}
                    target="client"
                    data={clientData}
                    fieldName="allowChangingInvestorProfile"
                    withTitle={withPartner}
                    displayEntity={displayEntity}
                />
                {withPartner && (
                    <Card
                        firstName={partnerFirstName}
                        lastName={partnerLastName}
                        target="partner"
                        data={partnerData}
                        fieldName="partnerAllowChangingInvestorProfile"
                        withTitle
                        displayEntity={displayEntity}

                    />
                )}
            </div>
            {submitSucceeded && isErrors && <Error errortext="Vous devez remplir le questionnaire." forceerror />}
            <p className="help-block">
                L&apos;ensemble des questions et vos réponses sont tenues à votre disposition par
                l&apos;intermédiaire de votre conseiller.
            </p>
        </FormGroup>
    );
};

InvestorProfile.propTypes = {
    displayEntity: PropTypes.shape({}),
    familySituation: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    investorProfiles: PropTypes.shape({}),
    isErrors: PropTypes.shape({}),
    lastName: PropTypes.string.isRequired,
    partnerFirstName: PropTypes.string,
    partnerLastName: PropTypes.string,
    submitSucceeded: PropTypes.bool,
};

InvestorProfile.defaultProps = {
    familySituation: null,
    partnerFirstName: null,
    investorProfiles: {},
};

const mapStateToProps = (state) => ({
    isErrors: state.form['rip-profil'].syncErrors,
    submitSucceeded: state.form['rip-profil'].submitSucceeded,
});

export default flowRight([
    formValues({
        firstName: 'firstName',
        lastName: 'lastName',
        partnerLastName: 'partnerLastName',
        partnerFirstName: 'partnerFirstName',
        familySituation: 'familySituation',
        investorProfiles: 'investorProfiles',
    }),
    connect(mapStateToProps),
])(InvestorProfile);
