import React from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import SettingEntityDropdownInteger from '../SettingEntityDropdownInteger';
import FormFieldsWhitoutFooter from '../../FormFieldsWhitoutFooter';

/* eslint-disable no-param-reassign */
// Fonction utilitaire pour les validations
const checkRequiredIntegerField = (field, values, errors) => {
    if (!values[field]) {
        errors[field] = `Le champ ${field} est requis`;
    }
};

const checkIsSuperior = (field, reference, message, values, errors) => {
    if (values[field] <= values[reference]) {
        errors[field] = message;
    }
};

const checkIsInferior = (field, maxValue, message, values, errors) => {
    if (values[field] >= maxValue) {
        errors[field] = message;
    }
};

// Fonction de validation principale
const validateVariousEntitySettings = (values) => {
    const errors = {};

    // Vérifications "required"
    checkRequiredIntegerField('cautiousMin', values, errors);
    checkRequiredIntegerField('cautiousMax', values, errors);
    checkRequiredIntegerField('balancedMin', values, errors);
    checkRequiredIntegerField('balancedMax', values, errors);
    checkRequiredIntegerField('dynamicMin', values, errors);
    checkRequiredIntegerField('dynamicMax', values, errors);

    // Vérifications "supérieur"
    checkIsSuperior('cautiousMax', 'cautiousMin', 'La valeur doit être supérieure à la valeur de prudent Min', values, errors);
    checkIsSuperior('balancedMin', 'cautiousMax', 'La valeur doit être supérieure à la valeur de prudent Max', values, errors);
    checkIsSuperior('balancedMax', 'balancedMin', 'La valeur doit être supérieure à la valeur d\'équilibré Min', values, errors);
    checkIsSuperior('dynamicMin', 'balancedMax', 'La valeur doit être supérieure à la valeur d\'équilibré Max', values, errors);
    checkIsSuperior('dynamicMax', 'dynamicMin', 'La valeur doit être supérieure à la valeur de dynamique Min', values, errors);

    // Vérifications "inférieur" ou bornes
    checkIsSuperior('cautiousMin', 0, 'La valeur doit être supérieure à 0', values, errors);
    checkIsInferior('dynamicMax', 8, 'La valeur doit être inférieure à 8', values, errors);

    return errors;
};

const SRIprofilesInner = ({ ...props }) => (
    <FormFieldsWhitoutFooter {...props} back="/admin/settings">
        <SettingEntityDropdownInteger
            title="Paramètres profils SRI"
            name="SRIprofiles"
            isAdminReseau
            {...props}
        />
    </FormFieldsWhitoutFooter>
);

const SRIprofiles = compose(reduxForm({
    form: 'entity-settings-SRIprofiles-edit',
    enableReinitialize: true,
    validate: validateVariousEntitySettings,
}))(SRIprofilesInner);

export default SRIprofiles;
