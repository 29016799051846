import { flowRight } from 'lodash/fp';
import { reduxForm, SubmissionError } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import { withPropsOnChange, withState } from 'recompose';
import fullName from 'common/utils/fullName';
import withSpinner from 'components/withSpinner';
import Icon from 'common/components/Icon';
import { listProvider } from 'components/list';
import { POST } from 'utils/httpMethods';
import { withSession } from 'components/sessions';
import { SearchableSelectField } from '../../../form/field';
import RipFormFields from '../../FormFields';
import withRouter from '../../../withRouter';

const ReattributeInner = ({ users, render, navigate, ...props }) => {
    if (render) {
        return (
            <div className="transfer-ownership-failed rip">
                <div className="not-updated">
                    <h3> <Icon icon="warning" className="fa-fw" /> La réaffectation n&apos;a pas pu se faire,<br /> le RIP est associé à un eDOC et le conseiller n&apos;a pas de compte eDOC</h3>
                </div>
                <button className="sendButton" onClick={() => navigate('/')}>
                    OK
                </button>
            </div>
        );
    }

    return (
        <RipFormFields {...props}>
            <SearchableSelectField
                name="nextUserId"
                title="Sélectionnez le nom du nouveau CGP"
                options={users}
            />
            <button className="sendButton" type="submit">
                Réattribuer&nbsp;
                <Icon icon="angle-right" />
            </button>
        </RipFormFields>
    );
};

ReattributeInner.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({})),
    render: PropTypes.bool,
    navigate: PropTypes.func,
};

const Reattribute = flowRight([
    listProvider({
        sorts: {
            lastName: { initialOrder: 'asc', default: true },
        },
        name: 'user',
        type: 'user',
    }),
    withSpinner,
    withRouter,
    withState('render', 'setRender', false),
    withSession,
    reduxForm({
        form: 'rip-reassign',
        onSubmit: async (body, dispatch, { params: { id } }) => {
            try {
                const res = await POST('/api/rips/changeOwnership', { body: { ...body, rips: [id] } });

                return res.json();
            } catch (error) {
                const data = await error.response.json();
                throw new SubmissionError({
                    _error: data,
                });
            }
        },

        onSubmitSuccess: (result, dispatch, { setRender, session, navigate }) => {
            //* update shoyo uniquement pour instance theseis
            if (session.instanceTheseis) {
                const { statusCode, body: { notUpdated } } = result;
                if (statusCode !== 200 && notUpdated.length > 0) {
                    setRender(true);
                }
                if (200 === statusCode && 0 === notUpdated.length) {
                    navigate('/');
                }
            } else {
                navigate('/');
            }
        },
    }),
    withPropsOnChange('items', ({ items }) => ({
        users: items.map(({ id, firstName, lastName }) => ({ value: id, label: fullName({ firstName, lastName }) })),
    })),
])(ReattributeInner);
export default Reattribute;
