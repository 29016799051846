import { flowRight, get } from 'lodash/fp';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { contractStates } from 'common/choices';
import isSimuAvailableForContract from 'common/utils/isSimuAvailableForContract';
import { GET, POST } from 'utils/httpMethods';
import { Action, Actions, Date, Initials, Item, connectActiveItem } from '../list';
import { verifyPrerequisites } from './lifeInsurance/Verify';
import { verifyPerPrerequisites } from './per/Verify';
import getSettings from '../../common/utils/settings';
import getLifeInsuranceData from '../../common/utils/getLifeInsuranceData';
import getPerData from '../../common/utils/getPerData';
import { setModal } from '../../actions';
import WarningModal from './warningModal';

const capitelizeFirstLetter = (string) => {
    if (!string) { return ''; }

    return (
        string.charAt(0).toUpperCase() + string.slice(1)

    );
};

const ListItem = ({ rip, item, isActive, show, open }) => {
    const navigate = useNavigate();

    const handleOpen = () => {
        open(navigate);
    };
    const contractType = get('simulation.data.contractType', item);
    const product = get('simulation.product', item);

    const types = 'per' === product ? 'contracts' : 'types';
    const isPer = 'per' === product;

    const subscription = getSettings(`simulation.${product}.${types}.${contractType}.subscription`);
    const verify = (val, typer) => {
        const verifyContract = isPer ? verifyPerPrerequisites(val, typer) : verifyPrerequisites(val, typer);

        return verifyContract;
    };

    const contractStateProduct = () => {
        const contractState = isPer ?
            <td>{get([getPerData(contractType, 'cardif', 'mma'), item.state], contractStates)}</td>
            :
            <td>{get([getLifeInsuranceData(contractType, 'target', 'pvp'), item.state], contractStates)}</td>;

        return contractState;
    };

    const shouldRedirectToHub = (subscription && !isPer && 'completedDigital' === item.state) || (isPer && 'completedDigital' === item.state);

    const redirectMessage = isPer ? `accéder au portail ${capitelizeFirstLetter(get('simulation.data.subscriptionChoice', item))}` : 'accéder au hub primonial';

    const getContractType = getPerData(contractType, 'cardif', 'mma');

    const redirectToHub = async () => {
        if (isPer) {
            try {
                const response = await POST(`/api/contracts/${item.id}/per/url`);
                const { url } = await response.json();
                window.open(url, '_blank');
            } catch (error) {
                console.error('error get per/url => ', error);
            }
        } else {
            const response = await GET(`/api/contracts/${item.id}/primonial/url`);
            const { hubUrl } = await response.json();

            window.open(`/sso-redirect-primonial?url=${encodeURIComponent(hubUrl)}`, '_blank');
        }
    };

    return (
        <Item
            displayActions={isActive}
            onFocus={!isActive ? () => show() : null}
        >
            <Initials firstName={rip.firstName} lastName={rip.lastName} />
            <td>{item.simulation.name}</td>
            <Date date={item.createdAt} />
            <Date date={item.updatedAt} />
            <td>{contractStateProduct()}</td>

            <Actions>
                {shouldRedirectToHub ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '130px' }}>
                        <Action onClick={redirectToHub} icon="desktop" text={redirectMessage} />
                        {'mma' === getContractType ? (
                            <Action onClick={handleOpen} icon="return" text="renvoyer" />
                        ) : null }
                    </div>
                ) : (
                    <Action
                        to={isSimuAvailableForContract(subscription)
                            && verify(get('data.verify', item), contractType)
                            ? `/rip/${rip.id}/contracts/${item.id}/conditions/${contractType}`
                            : `/rip/${rip.id}/contracts/${item.id}/verifications/${contractType}`
                        }
                        icon="pencil"
                        text="modifier"
                    />
                )}
            </Actions>
        </Item>
    );
};

ListItem.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
    item: PropTypes.shape({
        simulation: PropTypes.shape({
            name: PropTypes.string,
        }),
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        state: PropTypes.string,
        id: PropTypes.string,
        refAct: PropTypes.string,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    show: PropTypes.func.isRequired,
    open: PropTypes.func,
};

export default flowRight([
    connectActiveItem(
        ({ item: { id } }) => id,
        (item, { activeItem }) => ({ isActive: item === activeItem }),
    ),
    connect(null, (dispatch, { rip, item }) => ({
        open: (navigate) => {
            const handleConfirm = () => {
                dispatch(setModal(null));
                navigate(`/rip/${rip.id}/contracts/${item.id}/cardifCssWarning/${item.simulation.data.contractType}`);
            };

            dispatch(setModal(() => (
                <WarningModal
                    title="Attention"
                    message={
                        <Fragment>
                            Cette opération est uniquement prévue pour les souscriptions en erreur sur l&apos;eRIP.
                            <br />
                            Si vous continuez, la dernière souscription ne sera plus accessible.
                        </Fragment>
                    }
                    close={() => dispatch(setModal(null))}
                    onConfirm={handleConfirm}
                />
            ), true));
        },
    })),
    pure,
])(ListItem);
