import PropTypes from 'prop-types';
import React from 'react';
import { IntegerField } from 'components/form/field';
import './LegalDisclaimerMinMax.css';

const LegalDisclaimerMinMax = ({ isAdminReseau }) => {
    const profiles = [
        { name: 'Prudent', min: 'cautiousMin', max: 'cautiousMax' },
        { name: 'Équilibré', min: 'balancedMin', max: 'balancedMax' },
        { name: 'Dynamique', min: 'dynamicMin', max: 'dynamicMax' },
    ];

    const getFieldName = (field) => (isAdminReseau ? field : `global.legalDisclaimer.SRIprofiles.${field}`);

    return (
        <div className="containerLegalDisclaimerMinMax">
            <table>
                <thead>
                    <tr>
                        <th className="firstTitle">Profils</th>
                        <th className="secondTitle" colSpan="2">Échelles</th>
                    </tr>
                    <tr>
                        <th />
                        <th className="columnMin">Min</th>
                        <th className="columnMax">Max</th>
                    </tr>
                </thead>
                <tbody>
                    {profiles.map(profile => (
                        <tr key={profile.name}>
                            <td>{profile.name}</td>
                            <td className="inputMin">
                                <IntegerField name={getFieldName(profile.min)} />
                            </td>
                            <td className="inputMax">
                                <IntegerField name={getFieldName(profile.max)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

LegalDisclaimerMinMax.propTypes = {
    isAdminReseau: PropTypes.bool,
};

export default LegalDisclaimerMinMax;
