import PropTypes from 'prop-types';
import { has } from 'lodash/fp';
import React, { Fragment } from 'react';

import { investmentTimeHorizon as choices } from '../../../choices/investorProfile';

const SRIInfo = () => (
    <Fragment>
        <br />
        <span className="SRIInfo" style={{ color: '#737373', fontSize: '14px', marginTop: '15px' }}>
            *SRI : indicateur de risque sur une échelle de 1 à 7
        </span>
    </Fragment>
);

const InvestorProfile = ({ type, horizon, min, max }) => (
    <Fragment>
        <span>
            Compte tenu de l&apos;ensemble des éléments que vous nous avez communiqué, vous êtes
            un investisseur <b>{type}</b> avec un horizon d&apos;investissement de {horizon}.
            Votre profil SRI* se situe entre {min} et {max}.
        </span>
        <SRIInfo />
    </Fragment>
);

const profiles = (investementTimeHorizon, displayEntity) => {
    const { SRIprofileCautiousMin, SRIprofileCautiousMax, SRIprofileBalancedMin, SRIprofileBalancedMax, SRIprofileDynamicMin, SRIprofileDynamicMax } = displayEntity;

    const horizon = has(investementTimeHorizon, choices)
        ? choices[investementTimeHorizon].toLowerCase()
        : null;

    return {
        P: (
            <InvestorProfile
                type="prudent"
                horizon={horizon}
                min={SRIprofileCautiousMin}
                max={SRIprofileCautiousMax}
            />
        ),
        E: (
            <InvestorProfile
                type="équilibré"
                horizon={horizon}
                min={SRIprofileBalancedMin}
                max={SRIprofileBalancedMax}
            />
        ),
        D: (
            <InvestorProfile
                type="dynamique"
                horizon={horizon}
                min={SRIprofileDynamicMin}
                max={SRIprofileDynamicMax}
            />
        ),
        declined: (
            <span>
                Vous n&apos;avez pas souhaité répondre au questionnaire de connaissances règlementaire.
                Ainsi, nous sommes dans le regret de vous informer que la réglementation nous interdit de vous formuler
                toute préconisation.
            </span>
        ),
    };
};

InvestorProfile.propTypes = {
    horizon: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    type: PropTypes.string,
};

export default profiles;
