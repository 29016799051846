import classNames from 'classnames';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { compose } from 'recompose';
import computeInvestorProfile, { profiles } from 'common/model/rip/investorProfile';
import fullName from 'common/utils/fullName';
import Icon from 'common/components/Icon';
import { setInvestorProfileSource } from 'actions';
import Checkbox from 'components/form/Checkbox';
import withRouter from '../../withRouter';

import './InvestorProfileCard.scss';

const allowChangingInvestorProfileTitle = (firstName, lastName) => (
    <span>
        Pour un investissement qui représente moins de 10% de mon patrimoine global,
        moi <b>{fullName({ firstName, lastName })}</b>, souhaite un profil de risque plus dynamique que mon profil
        de risque global et je confirme que mon horizon d’investissement est supérieur à 5 ans.
    </span>
);

const InvestorProfileCard = ({
    firstName,
    lastName,
    target,
    data,
    setSource,
    withTitle,
    fieldName,
    navigate,
    params: { id },
    displayEntity,
}) => {
    const investorProfile = computeInvestorProfile(data);

    const toInvestorProfile = () => {
        // persist the entry point to come back
        setSource(`/rip/${id}/profil-client`, 'Retour à l\'édition du RIP');

        // then move on the investor profile
        return navigate(`/rip/${id}/investorProfile/${target}`);
    };

    return (
        <div className="investor-profile-card">
            {withTitle && <span className="title"><Icon icon="userN" className="fa-fw" /> {firstName}</span>}
            <div className={classNames('body', { nc: !investorProfile })}>
                {!data && (
                    <div>
                        <Icon icon="ban" className="fa-4x" />
                        <span>Non communiqué</span>
                    </div>
                ) }
                {data && !investorProfile ? (
                    <div className="warning">
                        <Icon icon="warning" className="fa-4x" />
                        <span>Une erreur est survenue.<br />Merci d&apos;actualiser les réponses du questionnaire</span>
                    </div>
                ) : (
                    <p>{get(investorProfile, profiles(get('investmentTimeHorizon', data), displayEntity))}</p>
                )}
                {investorProfile && (
                    <Fragment>
                        <hr />
                        <Field
                            title={allowChangingInvestorProfileTitle(firstName, lastName)}
                            component={Checkbox}
                            name={fieldName}
                        />
                    </Fragment>
                )}
            </div>
            <div className="text-right">
                <button onClick={toInvestorProfile}>
                    <Icon icon="pencil" className="fa-fw" /> Accéder au questionnaire
                </button>
            </div>
        </div>
    );
};

InvestorProfileCard.propTypes = {
    data: PropTypes.shape({}),
    displayEntity: PropTypes.shape({}),
    fieldName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    params: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    setSource: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired,
    withTitle: PropTypes.bool,
};

InvestorProfileCard.defaultProps = {
    data: null,
    withTitle: false,
};

export default compose(
    withRouter,
    connect(null, { setSource: setInvestorProfileSource }),
)(InvestorProfileCard);
