import { checkIsInferior, checkIsSuperior, checkRequiredField, checkRequiredIntegerField } from '../lib';
import { lifeInsuranceProfiles } from '../../choices/settings/calculator';

const validators = [
    checkRequiredField('global.socialChargesRate'),
    checkRequiredField('global.pfu'),
    checkRequiredField('finance.livretARate'),
    checkRequiredField('finance.LDDRate'),
    checkRequiredField('calculator.lifeInsurance.inflation'),
    ...Object
        .keys(lifeInsuranceProfiles)
        .map((profile) => checkRequiredField(`calculator.lifeInsurance.profiles.${profile}`)),
    checkRequiredField('calculator.credit.loanRate20'),
    checkRequiredField('calculator.credit.loanRate25'),
    checkRequiredIntegerField('global.legalDisclaimer.notices'),
    checkRequiredIntegerField('global.legalDisclaimer.cookies'),
    checkRequiredIntegerField('global.legalDisclaimer.confidentiality'),
    checkRequiredIntegerField('global.legalDisclaimer.clientPdf'),

    // SRI profiles
    checkRequiredIntegerField('global.legalDisclaimer.SRIprofiles.cautiousMin'),
    checkRequiredIntegerField('global.legalDisclaimer.SRIprofiles.cautiousMax'),
    checkRequiredIntegerField('global.legalDisclaimer.SRIprofiles.balancedMin'),
    checkRequiredIntegerField('global.legalDisclaimer.SRIprofiles.balancedMax'),
    checkRequiredIntegerField('global.legalDisclaimer.SRIprofiles.dynamicMin'),
    checkRequiredIntegerField('global.legalDisclaimer.SRIprofiles.dynamicMax'),
    checkIsSuperior('global.legalDisclaimer.SRIprofiles.cautiousMax', 'global.legalDisclaimer.SRIprofiles.cautiousMin', 'La valeur doit être supérieure à la valeur de prudent Min'),
    checkIsSuperior('global.legalDisclaimer.SRIprofiles.balancedMin', 'global.legalDisclaimer.SRIprofiles.cautiousMax', 'La valeur doit être supérieure à la valeur de prudent Max'),
    checkIsSuperior('global.legalDisclaimer.SRIprofiles.balancedMax', 'global.legalDisclaimer.SRIprofiles.balancedMin', 'La valeur doit être supérieure à la valeur d\'équilibré Min'),
    checkIsSuperior('global.legalDisclaimer.SRIprofiles.dynamicMin', 'global.legalDisclaimer.SRIprofiles.balancedMax', 'La valeur doit être supérieure à la valeur d\' équilibré Max'),
    checkIsSuperior('global.legalDisclaimer.SRIprofiles.dynamicMax', 'global.legalDisclaimer.SRIprofiles.dynamicMin', 'La valeur doit être supérieure à la valeur de dynamique Min'),
    checkIsSuperior('global.legalDisclaimer.SRIprofiles.cautiousMin', 0, 'La valeur doit être supérieure à 0'),
    checkIsInferior('global.legalDisclaimer.SRIprofiles.dynamicMax', 8, 'La valeur doit être inférieure à 8'),
];

export default validators;
