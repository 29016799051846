// Impôt avis social (CSG + CRDS + RSA)
import compute from '../computeResultSocialCharges';

const computeSocialCharges = (rip, { landRentalResults, furnishedRentalResults }) => {
    const socialCharges = compute(landRentalResults) + compute(furnishedRentalResults);

    return { socialCharges };
};

export default computeSocialCharges;
