import { get, set, isNumber } from 'lodash/fp';

const checkIsInferior = (field1OrNumber, field2OrNumber, errorString) =>
    (errors, values) => {
        const firstValue = isNumber(field1OrNumber) ? field1OrNumber : get(field1OrNumber, values);
        const secondValue = isNumber(field2OrNumber) ? field2OrNumber : get(field2OrNumber, values);

        return (firstValue >= secondValue)
            ? set(field1OrNumber, errorString, errors)
            : errors;
    };

export default checkIsInferior;
