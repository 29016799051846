import { toPairs, isEmpty, flow, first, filter, flowRight, get, omit, find } from 'lodash/fp';
import PropTypes from 'prop-types';
import { withContext } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { TextAreaField } from 'components/form/field';
import Divider from 'components/Divider';
import hasSigned from 'utils/hasSigned';
import FinancialCapacity from './FinancialCapacity';
import InvestorProfile from './InvestorProfile';
import RipForm from '../Form';
import LifePlansList from './LifePlansList';
import wizardData from '../wizardData';
import dataForm from '../dataForm';
import withRouter from '../../withRouter';
import { setFormValidationDisplay } from '../../../actions';
import { useSession } from '../../sessions';

const FormInner = ({
    errors,
    initialValues,
    showErrors,
    ...props
}) => {
    const session = useSession();
    const { entity } = session;

    return (
        <RipForm
            {...props}
            nextButtonContent={hasSigned(initialValues) ? 'Signatures' : 'Récapitulatif'}
        >
            <LifePlansList />
            <FinancialCapacity />
            <InvestorProfile displayEntity={entity} />
            <Divider />
            <TextAreaField title="Commentaires" name="financialComment" />
        </RipForm>
    );
};
FormInner.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    showErrors: PropTypes.func,
};

const getFirstInvalidForm = flow([
    toPairs,
    filter(([, formErrors]) => !isEmpty(formErrors)),
    first,
    get(0),
]);

const Form = flowRight([
    // first we need the router
    withRouter,
    // then get validation errors across all steps
    connect(({ formValidation: { errors } }) => ({
        formErrors: omit(['rip-appointment', 'rip-commitment'], errors),
    })),
    // initialize the redux form
    dataForm({
        form: 'rip-profil',
        id: ({ id }) => id,
        type: 'rip',
        onSubmit: (values, dispatch, { formErrors: errors, navigate, params: { id } }) => {
            // look for errors
            const invalidStep = getFirstInvalidForm(errors);

            if (invalidStep) {
                // get the invalid step url
                const target = get('target', find(({ form }) => form === invalidStep, wizardData.items));
                // inform we want to show the errors on the next step
                dispatch(setFormValidationDisplay(true));
                // then redirect to the step
                navigate(`/rip/${id}/${target}`);
            } else {
                // redirect to the next page
                navigate(`/rip/${id}/commitment`);
            }
        },
    }),
    withContext(
        {
            change: PropTypes.func.isRequired,
        },
        ({ change }) => ({ change }),
    ),
])(FormInner);

export default Form;
