import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import LegalDisclaimer from './LegalDisclaimer';
import Consentement from './Consentement';
import SRIprofiles from './SRIprofiles';

const SettingEntity = ({ initialValues, ...props }) => (
    <div className="container">
        <h1 className="text-center">
            Paramètres Divers
        </h1>
        <Title><b>Légal</b></Title>
        <LegalDisclaimer initialValues={initialValues.legalDisclaimerRip} {...props} />
        <Consentement initialValues={initialValues.consentement} {...props} />
        <SRIprofiles initialValues={initialValues.SRIprofiles} {...props} />

    </div>
);

SettingEntity.propTypes = {
    initialValues: PropTypes.shape({
        legalDisclaimerRip: PropTypes.shape({}).isRequired,
        consentement: PropTypes.shape({}).isRequired,
        SRIprofiles: PropTypes.shape({
            cautiousMin: PropTypes.number,
            cautiousMax: PropTypes.number,
            balancedMin: PropTypes.number,
            balancedMax: PropTypes.number,
            dynamicMin: PropTypes.number,
            dynamicMax: PropTypes.number,
        }).isRequired,
    }).isRequired,
};

export default SettingEntity;
