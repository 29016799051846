import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import LegalDisclaimerMinMax from './LegalDisclaimerMinMax';

import './SaveButton.css';

const SettingEntityDropdownInteger = ({ title, isAdminReseau }) => (
    <Dropdown title={title}>
        <LegalDisclaimerMinMax isAdminReseau />

        {isAdminReseau &&
            <div className="submit" style={{ marginBottom: '15px' }}>
                <button className="sendSettingsButton" type="submit">
                    Enregistrer
                </button>
            </div>}

    </Dropdown>
);

SettingEntityDropdownInteger.propTypes = {
    title: PropTypes.string.isRequired,
    isAdminReseau: PropTypes.bool,
};

export default SettingEntityDropdownInteger;
