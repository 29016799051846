import { checkIsInferior, checkIsSuperior, checkRequiredField, checkRequiredIntegerField } from '../lib';

// validate file
const validators = [
    checkRequiredField('legalDisclaimerRip'),
    checkRequiredField('consentement'),

    // SRI profiles
    checkRequiredIntegerField('cautiousMin'),
    checkRequiredIntegerField('cautiousMax'),
    checkRequiredIntegerField('balancedMin'),
    checkRequiredIntegerField('balancedMax'),
    checkRequiredIntegerField('dynamicMin'),
    checkRequiredIntegerField('dynamicMax'),
    checkIsSuperior('cautiousMax', 'cautiousMin', 'La valeur doit être supérieure à la valeur de prudent Min'),
    checkIsSuperior('balancedMin', 'cautiousMax', 'La valeur doit être supérieure à la valeur de prudent Max'),
    checkIsSuperior('balancedMax', 'balancedMin', 'La valeur doit être supérieure à la valeur d\'équilibré Min'),
    checkIsSuperior('dynamicMin', 'balancedMax', 'La valeur doit être supérieure à la valeur d\' équilibré Max'),
    checkIsSuperior('dynamicMax', 'dynamicMin', 'La valeur doit être supérieure à la valeur de dynamique Min'),
    checkIsSuperior('cautiousMin', 0, 'La valeur doit être supérieure à 0'),
    checkIsInferior('dynamicMax', 8, 'La valeur doit être inférieure à 8'),
];

export default validators;
