import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import SettingEntityDropdown from '../SettingEntityDropdown';
import FormFields from '../../FormFields';

const legalDisclaimerInner = ({ initialValues, ...props }) => (
    <FormFields {...props} back="/admin/settings" isHiddenButton>
        <SettingEntityDropdown
            title="Mentions légales (Rip client)"
            name="legalDisclaimerRip"
            isAdminReseau
            variables={{
                mentionsLegalesReseau: 'Mentions légales du réseau',
                adresseLegaleReseau: 'Adresse du réseau',
                nomReseau: 'Nom d\'affichage du réseau',
            }}
            {...props}
        />
    </FormFields>
);

legalDisclaimerInner.propTypes = {
    initialValues: PropTypes.shape({
        legalDisclaimerRip: PropTypes.string.isRequired,
    }).isRequired,
};

const legalDisclaimer = compose(reduxForm({
    form: 'entity-settings-legalDisclaimer-edit',
    enableReinitialize: true,
}))(legalDisclaimerInner);

export default legalDisclaimer;
