// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerLegalDisclaimerMinMax {
    width: 60%;
    margin: 0 auto;
    padding: 20px;

    table {
        width: 100%;
        border-collapse: collapse;

        th {
            color: #2196F3;
            font-weight: bold;
            padding: 8px;
        }

        td {
            padding: 5px;
            vertical-align: text-bottom;
        }
        .secondTitle {
            text-align: center;
        }
        .columnMin, .columnMax {
            width: 40%; 
            text-align: center;
        }

        .inputMin, .inputMax {
            text-align: center;

            .form-group
            {
                margin-bottom: 1rem;
            }
            h5 {
                display: none;
            }
            
            input {
                width: 100%;
                border: none;
                text-align: center;
            
                &:focus {
                    border-bottom: 2px solid #1976D2;
                }
            }
        }
    }
}


`, "",{"version":3,"sources":["webpack://./src/components/admin/settings/LegalDisclaimerMinMax.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,aAAa;;IAEb;QACI,WAAW;QACX,yBAAyB;;QAEzB;YACI,cAAc;YACd,iBAAiB;YACjB,YAAY;QAChB;;QAEA;YACI,YAAY;YACZ,2BAA2B;QAC/B;QACA;YACI,kBAAkB;QACtB;QACA;YACI,UAAU;YACV,kBAAkB;QACtB;;QAEA;YACI,kBAAkB;;YAElB;;gBAEI,mBAAmB;YACvB;YACA;gBACI,aAAa;YACjB;;YAEA;gBACI,WAAW;gBACX,YAAY;gBACZ,kBAAkB;;gBAElB;oBACI,gCAAgC;gBACpC;YACJ;QACJ;IACJ;AACJ","sourcesContent":[".containerLegalDisclaimerMinMax {\n    width: 60%;\n    margin: 0 auto;\n    padding: 20px;\n\n    table {\n        width: 100%;\n        border-collapse: collapse;\n\n        th {\n            color: #2196F3;\n            font-weight: bold;\n            padding: 8px;\n        }\n\n        td {\n            padding: 5px;\n            vertical-align: text-bottom;\n        }\n        .secondTitle {\n            text-align: center;\n        }\n        .columnMin, .columnMax {\n            width: 40%; \n            text-align: center;\n        }\n\n        .inputMin, .inputMax {\n            text-align: center;\n\n            .form-group\n            {\n                margin-bottom: 1rem;\n            }\n            h5 {\n                display: none;\n            }\n            \n            input {\n                width: 100%;\n                border: none;\n                text-align: center;\n            \n                &:focus {\n                    border-bottom: 2px solid #1976D2;\n                }\n            }\n        }\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
