import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import withRouter from '../withRouter';

import './warningModal.scss';

const warningModal = ({ title, message, close, onConfirm }) => (
    <div className="warning-modal-container">
        <div className="warning-modal-title">
            <h3>
                { title.toUpperCase() }
            </h3>
        </div>
        <p>
            { message}
        </p>
        <p>
            Voulez-vous continuer ?
        </p>
        <div className="warning-modal-content-button">
            <button
                onClick={onConfirm}
                type="button"
            >
                OUI
            </button>
            <button
                onClick={close}
                type="button"
            >
                NON
            </button>
        </div>
    </div>
);

warningModal.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.shape({}).isRequired,
};

export default compose(
    withRouter,
    connect(null, (dispatch) => ({
        open: () => {
            dispatch(setModal(null));
        },
        close: () => {
            dispatch(setModal(null));
        },

    })),
)(warningModal);
